<template>
  <v-dialog
      v-model="dialog"
      max-width="600"
      scrollable
  >
    <v-card>
      <v-toolbar dark>
        <v-card-title class="text-h5">
          <span>{{ title }} Download Category</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" md="12">
              <v-text-field
                  v-model="downloadCategory.name"
                  :error="$v.downloadCategory.name.$error"
                  outlined
                  dense
              >
                <template v-slot:label>
                  Name <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.downloadCategory.name.$error">Name is required</span>
              <span class="text-danger" v-if="errors.name" >* {{ errors.name[0] }}</span>
            </v-col>


            <v-col cols="12">
              <v-select
                  @change="resetUser"
                  v-model="downloadCategory.access_type"
                  :items="accessTypes"
                  item-text="name"
                  item-value="value"
                  outlined
                  dense
                  :error="$v.downloadCategory.access_type.$error"
                  @input="downloadCategory.access_type = $event !== null ? $event : ''"
                  clearable
              >
                <template v-slot:label>
                  Access Type <span class="text-danger">*</span>
                </template>
              </v-select>
              <span class="text-danger" v-if="$v.downloadCategory.access_type.$error">Access Type is required</span>
            </v-col>

            <v-col cols="12" v-if="downloadCategory.access_type == 'group'">
              <v-select
                  v-model="downloadCategory.access_group"
                  :items="accessGroups"
                  item-text="name"
                  item-value="value"
                  :error="$v.downloadCategory.access_group.$error"
                  @input="downloadCategory.access_group = $event !== null ? $event : ''"
                  multiple
                  small-chips
                  deletable-chips
                  clearable
                  outlined
                  dense
              >
                <template v-slot:label>
                  Access Group <span class="text-danger">*</span>
                </template>
                <template v-slot:prepend-item>
                  <v-list-item
                      ripple
                      @click="selectAllGroups"
                  >
                    <v-list-item-action>
                      <v-icon :color="downloadCategory.access_group.length > 0 ? 'indigo darken-4' : ''">
                        {{ icon }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        Select All
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-1"></v-divider>
                </template>
              </v-select>
              <span class="text-danger" v-if="$v.downloadCategory.access_group.$error">Access Group is required</span>
            </v-col>

            <v-col cols="12" v-if="selectedUsers.length > 0">
              <v-chip class="ma-2" @click:close="removeUser(index)" color="primary"
                      v-for="(examiner,index) in selectedUsers" :key="index"
                      label close>
                {{ examiner }}
              </v-chip>
            </v-col>
            <v-col cols="12" v-if="downloadCategory.access_type == 'user'">
              <v-autocomplete
                  @change="getUser"
                  v-model="selectedUser"
                  :search-input.sync="userSearch"
                  :items="items"
                  :loading="isLoading"
                  item-text="display_text"
                  item-value="id"
                  label="Applicable Users"
                  return-object
                  hide-selected
                  outlined
                  dense
                  clearable
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        <strong>Please Type Email, First Name or Unique Identifier</strong>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-autocomplete>
            </v-col>

            <v-col cols="12">
              Description <span class="text-danger">*</span>
              <ckeditor
                :config="editorConfig"
                v-model="downloadCategory.description"
              >
              </ckeditor>
              <span class="text-danger" v-if="$v.downloadCategory.description.$error">Description Field is required</span>
              <span class="text-danger" v-if="errors.description" >* {{ errors.description[0] }}</span>
            </v-col>

            <v-col cols="12" md="6">
              Status
              <v-switch
                  v-model="downloadCategory.is_active"
                  :label="downloadCategory.is_active ? 'Active' : 'Inactive'"
              ></v-switch>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
            text
            @click="closeDialog">
          Cancel
        </v-btn>

        <v-btn
            class="btn btn-primary"
            @click="createOrUpdate()"
            :loading="loading"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DownloadCategoryService from "@/services/cms/download/category/DownloadCategoryService";
import {required, requiredIf} from "vuelidate/lib/validators";
import UserService from "@/services/user/UserService";

const downloadCategory = new DownloadCategoryService();
const user = new UserService();

export default {
  name: "CreateAndUpdate",
  data() {
    return {
      title: '',
      edit: false,
      dialog: false,
      isLoading: false,
      loading: false,
      users: [],
      selectedUsers: [],
      selectedUser: {},
      errors: [],
      userSearch: null,
      accessTypes: [
        { name: "Group", value: "group" },
        { name: "User", value: "user" },

      ],
      accessGroups: [
        { name: "Home Page", value: "homepage" },
        { name: "Agent", value: "agent" },
        { name: "Student", value: "student" }
      ],
      downloadCategory: {
        name: '',
        position: '',
        access_type: 'group',
        access_group: [],
        applicable_users: [],
        description: '',
        is_active: true,
      },
      editorConfig: {
        versionCheck: false,
        toolbar: [
          [
            'Font', 'FontSize', 'FontColor', 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList', 'TextColor', 'BGColor'
          ]
        ],
        extraPlugins: ['font', 'colorbutton'],
        colorButton_enableMore: true,
      }
    }
  },
  validations: {
    downloadCategory: {
      name: { required },
      access_type: { required },
      access_group: { required:requiredIf(function () {
          return this.downloadCategory.access_type == 'group';
        }) },
      description: { required },
      is_active: { required }
    }
  },
  watch: {
    userSearch (val) {
      if (this.isLoading) return;
      if(val != null) {
        user
            .getAllByUserType({name: val})
            .then((response) => {
              this.users =response.data.users;
            })
            .catch(err => {
              // console.log(err)
            })
            .finally(() => (this.isLoading = false))
      }
    }
  },
  computed: {
    items() {
      return this.users.map((user, index) => {
        const display_text = user.first_name+' '+ user.last_name+ ' || '+user.email+' || '+user.unique_identifier;
        return Object.assign({}, user, { display_text });
      });
    },
    selectAllGroup () {
      return this.downloadCategory.access_group.length === this.accessGroups.length
    },
    selectedGroup () {
      return this.downloadCategory.access_group.length > 0 && !this.selectAllGroup
    },
    icon () {
      if (this.selectAllGroup) return 'mdi-close-box'
      if (this.selectedGroup) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
  },
  methods: {
    closeDialog(){
      this.dialog = false;
    },
    createDownloadCategory(){
      this.title = 'Add';
      this.dialog = true;
      this.edit = false;
      this.resetForm();
    },
    editDownloadCategory(downloadCategory){
      this.title = 'Edit';
      this.dialog = true;
      this.edit = true;
      this.downloadCategory = downloadCategory;
      if(downloadCategory.applicable_users_lists != null) {
        this.selectedUsers = downloadCategory.applicable_users_lists
      }
      if(downloadCategory.access_group == null) {
        this.downloadCategory.access_group = [];
      }
    },
    selectAllGroups () {
      this.$nextTick(() => {
        if (this.selectAllGroup) {
          this.downloadCategory.access_group = []
        } else {
          this.downloadCategory.access_group = [];
          this.accessGroups.forEach(type => {
            this.downloadCategory.access_group.push(type.value);
          });
        }
      })
    },
    getUser(){
      if(this.downloadCategory.applicable_users == null || this.downloadCategory.applicable_users == ''){
        this.downloadCategory.applicable_users = [];
      }

      if(this.selectedUser != null) {
        let full_name = this.selectedUser.first_name + ' '+ this.selectedUser.last_name;
        this.downloadCategory.applicable_users.push(this.selectedUser.id);
        this.selectedUsers.push(full_name);
      }

      this.selectedUser = null;
      this.userSearch = null;
      this.users = [];
    },
    removeUser(index){
      this.downloadCategory.applicable_users.splice(index,1);
      this.selectedUsers.splice(index,1);
    },
    resetUser() {
      if(this.downloadCategory.access_type == 'group') {
        this.downloadCategory.access_group = 'all';
      }
      if(this.users || this.downloadCategory.applicable_users) {
        this.downloadCategory.applicable_users = [];
        this.selectedUsers = [];
        this.users = [];
      }
    },
    createOrUpdate() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      } else {
        this.loading = true;
        if (!this.edit) this._create();
        else this.update();
      }
    },
    _create: function() {
      downloadCategory
          .create(this.downloadCategory)
          .then(response => {
            this.loading = false;
            this.$snotify.success("Download Category created successfully");
            this.resetForm();
            this.closeDialog();
            this.$emit('refresh');
          })
          .catch((err) => {
            this.loading = false;
            this.$snotify.error("Oops something went wrong");
            this.errors = err.errors;
          });
    },
    update() {
      downloadCategory
          .update(this.downloadCategory.id, this.downloadCategory)
          .then(response => {
            this.loading = false;
            this.$snotify.success("Download Category updated successfully");
            this.resetForm();
            this.closeDialog();
            this.$emit('refresh');
          })
          .catch((err) => {
            this.loading = false;
            this.$snotify.error("Oops something went wrong");
            this.errors = err.errors;
          });
    },
    resetForm(){
      this.$v.$reset();
      this.selectedUsers = [];
      this.downloadCategory = {
        name: '',
        position: '',
        access_type: 'group',
        access_group: [],
        applicable_users: [],
        description: '',
        is_active: true,
      }
    }
  }
}
</script>

<style scoped>

</style>
